import {
  computed,
  useConfigStore,
  useNavigation,
  useI18n,
  useAuth,
  useTracker,
  useRouteBaseName,
  useRoute,
  isStringEqual,
} from '#imports';

export const useFooterConfig = () => {
  const { navigationPath } = useNavigation();
  const { t, locale } = useI18n();
  const { jitsuEvent } = useTracker();
  const config = useConfigStore();
  const auth = useAuth();
  const getRouteBaseName = useRouteBaseName();
  const router = useRoute();

  const socmedLinks = [
    {
      id: 'instagram',
      to: 'https://www.instagram.com/airpaz/',
      icon: 'instagram',
    },
    {
      id: 'facebook',
      to: 'https://www.facebook.com/Airpaz/',
      icon: 'facebook-f',
    },
    {
      id: 'tiktok',
      to: 'https://www.tiktok.com/@airpazofficial',
      icon: 'tiktok',
    },
    {
      id: 'twitter',
      to: 'https://twitter.com/airpaz',
      icon: 'twitter',
    },
    {
      id: 'youtube',
      to: 'https://www.youtube.com/channel/UCdEnCSG9IHsIPh2B2gHhdHw',
      icon: 'youtube',
    },
    {
      id: 'linkedin',
      to: 'https://www.linkedin.com/company/airpaz-com',
      icon: 'linkedin-in',
    },
  ];

  const storeLinks = computed(() => [
    {
      img: {
        src: `download-app-banner/downloadapp-${locale.value}-googleplay.png`,
        fallback: 'download-app-banner/downloadapp-en-googleplay.png',
      },
      link: config.playstoreUrl!,
      alt: 'Google Playstore',
      event: 'downloadapp-playstore',
      eventNavbar: 'navbar-downloadapp-playstore-click',
    },
    {
      img: {
        src: `download-app-banner/downloadapp-${locale.value}-appstore.png`,
        fallback: 'download-app-banner/downloadapp-en-appstore.png',
      },
      link: config.appstoreUrl!,
      alt: 'App Store',
      event: 'downloadapp-appstore',
      eventNavbar: 'navbar-downloadapp-appstore-click',
    },
    {
      img: {
        src: `download-app-banner/downloadapp-${locale.value}-huawei.png`,
        fallback: 'download-app-banner/downloadapp-en-huawei.png',
      },
      link: config.appgalleryUrl!,
      alt: 'App Gallery',
      event: 'downloadapp-appgallery',
      eventNavbar: 'navbar-downloadapp-appgallery-click',
    },
  ]);
  const showGlobalHolidayLicense = computed(() => isStringEqual(config.country!, 'MY'));
  const showAustraliaLicense = computed(() => isStringEqual(config.country!, 'AU'));
  const navLinks = computed(() => {
    const links = [
      {
        section: 'Airpaz',
        links: [
          { text: t('global.home'), to: navigationPath('/'), external: false },
          { text: t('global.about'), to: navigationPath('/about'), external: false },
          { text: t('global.promo'), to: navigationPath('/promo'), external: false },
          { text: t('global.blog'), to: blogLink(), external: false },
          { text: t('global.airlinesinfo'), to: navigationPath('/airlines'), external: false },
          { text: t('airportpage.airportinformation'), to: navigationPath('/airports'), external: true },
          { text: t('global.allflight'), to: navigationPath('/flight/to'), external: true },
          { text: t('global.careers'), to: navigationPath('/careers'), external: false },
        ],
      },
      {
        section: t('global.account'),
        links: auth.loggedIn
          ? [{ text: t('profile.changepassword'), to: navigationPath('/account/password'), external: false }]
          : [
              { text: t('global.signup'), to: navigationPath('/signup'), external: false },
              { text: t('global.resetpassword'), to: navigationPath('/reset'), external: false },
            ],
      },
      {
        section: t('global.menusupport'),
        links: [
          { text: t('global.apzguide'), to: navigationPath('/guide'), external: false },
          // { text: t('global.faq'), to: navigationPath('/help'), external: false },
          { text: t('global.howtobook'), to: navigationPath('/guide/booking-flight'), external: false },
          { text: t('global.helpcenter'), to: navigationPath('/contact'), external: false },
          { text: t('global.termofuse'), to: navigationPath('/terms/uses'), external: false },
          { text: t('global.termandcondition'), to: navigationPath('/terms/condition'), external: false },
        ],
      },
    ];

    if (config.country === 'JP' || locale.value === 'ja') {
      links[0]?.links.push({ text: '特商法に基づく表記', to: navigationPath('/information-ja'), external: true });
    }

    return links;
  });

  const showAvailablePayments = computed(() => !['hotel', 'flight'].includes(getRouteBaseName(useRoute()) ?? ''));

  function blogLink() {
    let languageBlog = 'en';
    if (['en', 'id', 'zh', 'zh-tw', 'vi', 'ja'].includes(locale.value)) {
      languageBlog = locale.value;
    }
    return `https://blog.airpaz.com/${languageBlog}/`;
  }

  function onClickDownloadBanner(event: string) {
    const payload = {
      event_name: event,
      screen_name: getRouteBaseName(router) as string,
      object_name: 'bottom-footer',
      object_parameter: event,
    };

    if (event === 'downloadapp-appstore') {
      // @ts-ignore
      payload.section = 'bottom-footer';
      // @ts-ignore
      payload.button_name = 'downloadapp-appstore';
    }
    jitsuEvent('user-click-button', payload);
  }

  function onClickSocialMedia(id: string) {
    jitsuEvent('user-click-button', {
      screen_name: getRouteBaseName(router) as string,
      social_media: id,
      event_name: 'socialmedia-button-click',
      object_parameter: 'social-media',
      object_name: 'bottom-footer',
    });
  }

  return {
    showGlobalHolidayLicense,
    showAustraliaLicense,
    socmedLinks,
    storeLinks,
    navLinks,
    showAvailablePayments,
    onClickDownloadBanner,
    onClickSocialMedia,
  };
};
